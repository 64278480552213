<template>
    <div>
        <Header :seet="number" />
        <el-row type="flex" class="shoppCart-box">
            <el-col :span="3" style="width: 15.3%"></el-col>
            <el-col :span="17" style="min-width: 1100px;">
                <div class="shopp_cart">
                    <div class="shopp_cart_top flex-end">
                        <div class="address_box flex-start">
                            <span>配送至：</span>
                            <el-popover width="400" trigger="click" ref="popover">
                                <div>
                                <p>配送至</p>
                                <div class="addressList">
                                    <p
                                    class="item"
                                    v-for="(item, index) in addressList"
                                    :key="index"

                                    @click="addresshandleBlur(item)"
                                    >
                                    {{ item }}
                                    </p>
                                </div>
                                <div
                                    style="border-top: 1px solid #ddd; margin: 10px 0"
                                ></div>
                                <el-cascader
                                    :options="options"
                                    ref="cascaderAddr"
                                    v-model="selectAddress"
                                    @change="chooseAddress"
                                    placeholder="选择新地址"
                                >
                                </el-cascader>
                                </div>

                                <div class="address" slot="reference">
                                    <span class="iconfont icon-address"></span>
                                    <span>{{ addressName }}</span>
                                    <span class="iconfont icon-zhankai"></span>
                                </div>
                            </el-popover>
                            
                        </div>
                    </div>

                    <!-- 购物车列表 -->
                    <div class="shoppCart" v-if="shopCartList.length != 0">
                        <el-table
                            max-height="600px"
                            ref="multipleTable"
                            :data="shopCartList"
                            border
                            style="width: 100%"
                            tooltip-effect="dark"
                            @selection-change="handleSelectionChange"
                            size="mini"
                        >
                            <el-table-column
                                align="center"
                                type="selection"
                                :selectable="selectable"
                                width="55"
                            >
                            </el-table-column>

                            <el-table-column align="left" label="商品信息">
                                <template slot-scope="scope">
                                    <div
                                        class="flex-start"
                                        style="cursor: pointer;"
                                        @click="goodsDetail(scope.row.goodsId)"
                                    >
                                        <!-- <el-image
                                            class="goodsImg"
                                            :src="scope.row.homePicture"
                                        ></el-image> -->
                                        <div style="position: relative;">
                                            <img
                                                class="goodsImg"
                                                :src="scope.row.homePicture"
                                                alt=""
                                            />
                                            <div v-if="scope.row.sourceType===0">
                                                <div v-if="scope.row.stock===0" class="goodsNo">
                                                    {{scope.row.state==1?'无货':scope.row.state==2?'无货':scope.row.state==6?'无货':''}}
                                                </div>
                                                <div v-if="scope.row.state===3" class="goodsNo">
                                                    下架
                                                </div>
                                            </div>
                                            <div v-if="scope.row.sourceType===1">
                                                <div v-if="scope.row.jdStock==300" class="goodsNo">
                                                    {{'无货'}}
                                                </div>
                                            </div>
                                            <div v-if="scope.row.sourceType===2">
                                                <div v-if="scope.row.jdStock=='无货'" class="goodsNo">
                                                    {{'无货'}}
                                                </div>
                                            </div>
                                            <!-- <div class="goodsNo" v-show="cartno==1">无货</div>
                                            <div class="goodsNo" v-show="cartno==2">下架</div> -->
                                        </div>
                                        
                                        <span class="goodsName">
                                            <span class="title_name" v-show="scope.row.sourceType == 0"
                                            >自营</span
                                            >
                                            <span class="title_name" v-show="scope.row.sourceType == 1"
                                            >京东</span
                                            >
                                            <span class="title_name" v-show="scope.row.sourceType == 2"
                                            >震坤行</span
                                            >
                                            <span class="title_name" v-show="scope.row.sourceType == 3"
                                            >怡亚通</span
                                            >
                                            {{scope.row.goodsName}}</span>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column
                                align="center"
                                label="规格"
                                width="120px"
                            >
                                <template slot-scope="scope">
                                    <div class="flex-center-center">
                                        <div style="text-align: left">
                                            <div
                                                v-for="(item, key) in scope.row
                                                    .productSpecValues"
                                                :key="key"
                                            >
                                                {{
                                                    item.valueName +
                                                    "：" +
                                                    item.value
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column
                                align="center"
                                label="单价"
                                width="120px"
                            >
                                <template slot-scope="scope">
                                    <div>
                                        <div>￥{{ scope.row.price }}</div>
                                        <p
                                            style="color: #f2270c"
                                            v-if="
                                                scope.row.price !=
                                                scope.row.enterPrice
                                            "
                                        >
                                            {{
                                                floatPrice(
                                                    scope.row.price,
                                                    scope.row.enterPrice
                                                )
                                            }}
                                        </p>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column
                                width="160px"
                                align="center"
                                label="数量"
                                class-name="tableInpNumBox"
                            >
                                <template slot-scope="scope">
                                    <div class="tableInpNum">
                                        <el-input-number
                                            style="margin-bottom: 8px;"
                                            size="mini"
                                            v-model="scope.row.nums"
                                            @change="changeNumber(scope.row)"
                                            :min="scope.row.minBuyNums"
                                            :max="
                                                scope.row.canBuyMaxNum
                                                    ? scope.row.canBuyMaxNum -
                                                    scope.row.buyNums
                                                    : 9999999999999
                                            "
                                            label="描述文字"
                                        ></el-input-number>
                                        <div v-show="scope.row.sourceType==0" class="tipsText">剩{{scope.row.stock}}件</div>
                                        <div v-show="scope.row.sourceType==1" class="tipsText">
                                            <div v-show="scope.row.jdStock=='-1'">暂无地址，请删除商品后重新加入</div>
                                            <div v-show="scope.row.jdStock=='300'">无货</div>
                                            <div v-show="scope.row.jdStock=='200'">有货</div>
                                            <div v-show="scope.row.jdStock>0&&scope.row.jdStock<50">剩{{scope.row.jdStock}}件</div>
                                        </div>
                                        <div v-show="scope.row.sourceType==2" class="tipsText">
                                            <div>{{scope.row.jdStock}}</div>
                                        </div>
                                        <div v-show="scope.row.sourceType==3" class="tipsText">
                                            <div>有货</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column
                                align="center"
                                width="180px"
                                label="小计"
                            >
                                <template slot-scope="scope">
                                    <div>
                                        <div>
                                            ￥{{total(scope.row.nums,scope.row.price) }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column
                                align="center"
                                width="60px"
                                label="操作"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="isDeleteGoods( scope.row.id, scope.$index )"
                                    >删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                        <!-- 合计 -->
                        <div class="tableTail flex-between-center">
                            <div
                                class="left flex-between-center"
                                style="width: 82%"
                            >
                                <div
                                    class="flex-around-center"
                                >
                                    <el-checkbox
                                        :indeterminate="isIndeterminate"
                                        v-model="checkAll"
                                        @change="handleSelectionChange1"
                                        >全选</el-checkbox
                                    >
                                    <el-button
                                        class="b2b2b2"
                                        type="text"
                                        @click="delSelectGoods()"
                                        >删除选中的商品</el-button
                                    >
                                    <el-button
                                        class="b2b2b2"
                                        type="text"
                                        :loading="exportLoading"
                                        @click="exportShoppingCartDatas()"
                                        >导出购物车</el-button
                                    >
                                    <el-button
                                        class="b2b2b2"
                                        type="text"
                                        :loading="exportLoading1"
                                        @click="exportBulkorderDatas(1)"
                                        >导出批量下单模板</el-button
                                    >
                                    <el-button
                                        v-if="checkAuthFlagValue != 0"
                                        class="b2b2b2"
                                        type="text"
                                        :loading="exportLoading2"
                                        @click="exportBulkorderDatas(2)"
                                        >导出批量下单模板(全)</el-button
                                    >
                                </div>
                                <div
                                    class="flex-around-center"
                                    style="width: 39%"
                                >
                                    <span>
                                        已选中共<em style="color: red">{{
                                            goodsNumber
                                        }}</em
                                        >件商品
                                    </span>
                                    <span>
                                        总价：
                                        <b>
                                            <em style="color: red"
                                                >￥{{ totalAmount }}</em
                                            >
                                        </b>
                                    </span>
                                </div>
                            </div>
                            <div
                                class="settlement flex-center-center"
                                @click="throttle(null, 1000)"
                            >
                                去结算（{{ multipleSelection.length }}种）
                            </div>
                        </div>
                    </div>

                    <div class="no-shoppCart" v-else>
                        <img src="@/assets/image/cart-empty.png" alt="" />
                        <div>
                            <span>购物车空空如也，快去选购心仪的商品吧！</span>
                            <span class="goshopp" @click="goshopp()"
                                >去购物></span
                            >
                        </div>
                    </div>
                </div>
                <div class="guesslike">
                    <div class="guesslike_top">
                        <div>猜你喜欢</div>
                    </div>
                    <div class="guesslike_box">
                        <el-carousel ref='carousel' :autoplay="false" arrow="always" @change='carouselChange' :loop='true'>
                            <el-carousel-item v-for="(item, index) in fornum"
                                            :key="index">
                                <div class="guesslike_swiper">
                                    <div
                                        class="swiper-slide"
                                        v-for="(item, index) in shoppData"
                                            :key="index"
                                        
                                    >
                                        <div class="product-item" @click="gotocart(item.id)">
                                            <img :src="item.homePicture" alt="" />
                                            <div class="name"> 
                                                <span class="title_name" v-show="item.sourceType == 0"
                                                >自营</span>
                                                <span class="title_name" v-show="item.sourceType == 1"
                                                >京东</span>
                                                <span class="title_name" v-show="item.sourceType == 2"
                                                >震坤行</span>
                                                <span class="title_name" v-show="item.sourceType == 3"
                                                >怡亚通</span>
                                                {{ item.goodName }}
                                            </div>
                                            <div class="item_price">￥{{item.minPrice}}</div>
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </el-col>
            <el-col :span="3" style="width: 15.5%"></el-col>
        </el-row>

        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import {
    queryListAndPage,
    shoppingCartNumsChange,
    exportShoppingCartDatas,
    deleteShoppingCart,
    Inquire,
    exportBulkorderDatas,
    queryAndAddress,
    querySourceIdsAndAddress,
    checkAuthFlag,
    exportShoppingCartBatchDataAll
} from "@/api/api";
import { getAddress,restaddress } from "@/api/order/order";
import { authorityJudgment } from "@/utils/util"
let pcas = require("@/assets/json/pca-code.json");
export default {
    components: { Header, Footer },
    data() {
        return {
            exportLoading: false,
            exportLoading1: false,
            exportLoading2: false,
            prev: 0,
            number: "2",
            multipleSelection: [], //选中的商品数组
            isIndeterminate: false, //是否全选
            checkAll: false,
            shopCartList: [], //购物车列表
            pageNum: 1,
            pageSize: 9999999,
            goodsName: "",
            shoppData: [],
            fornum:1,
            orderList:[],
            cartno:0,//0有货，1售罄，2下架
            name: "",
            address: "",
            addressId: "",
            isDefault: 0,
            addressName: "", //选中的地址名称
            defaultAddress: {}, //默认地址
            localArress: {}, //本地地址
            addressList: [],
            selectAddress: "", //选择新的地址
            city: "",
            district: "",
            province: "",
            options: pcas,
            goodsIdList:[],
            stockList:[],
            ZKstockList:[],
            filterTime: null,
            checkAuthFlagValue: "0"
        };
    },
    computed: {
        //价格浮动
        floatPrice() {
            return (price, enterPrice) => {
                price = parseInt(price * 100);
                enterPrice = parseInt(enterPrice * 100);
                if (price > enterPrice) {
                    return `比加入购物车时涨价${(price - enterPrice) / 100}元`;
                } else {
                    return `比加入购物车时降价${(enterPrice - price) / 100}元`;
                }
            };
        },
        //小计
        total() {
            return (number, price) => {
                let total = (number * price).toFixed(2);
                // this.shopCartList[index].total = total;
                return total;
            };
        },
        //选中的商品数量
        goodsNumber() {
            let number = 0;
            this.multipleSelection.forEach((item) => {
                number += item.nums;
            });
            return number;
        },
        //总金额
        totalAmount() {
            let number = 0;
            this.multipleSelection.forEach((item) => {
                number += new Number(item.nums*item.price);
            });
            return number.toFixed(2);
        },
        // shopCartList: {
        //     get() {
        //         console.log(this.$store.state.shopCartList,'shopCartList');
        //         return this.$store.state.shopCartList;
        //     },
        //     set(){

        //     }
        // },
        MultipleSelection: {
          get () { return this.$store.state.common.MultipleSelection },
          set (val) { this.$store.commit('common/upmultipleSelection', val) }
        },
        GoodsInfor: {
          get () { return this.$store.state.common.GoodsInfor },
          set (val) { this.$store.commit('common/upgoodsInfor', val) }
        },
        
    },
    mounted() {
        // this.shopCartList = new Array(5).fill();
        this.queryListAndPage();
        this.getList(1,20)
    },
    created() {
        this.getLocation(); //获取本地地址
        this.getAddress(); //获取默认地址
        this.restaddress(); //获取其他地址
        this.onCheckAuthFlag(); //查询用户是否有【导出批量下单模板(全)】功能的权限
    },
    methods: {
        onCheckAuthFlag() {
            checkAuthFlag().then((res) => {
                if (res.code == "00000") {
                    this.checkAuthFlagValue = res.data
                }
            });
        },
        goodsDetail(id) {
            if(this.$routerWin){
                const { href } = this.$router.resolve({ name: "goodsDetail", query: { id: id } });
                window.open(href,'_blank');
            } else {
                this.$router.push({ name: "goodsDetail", query: { id: id } });
            }
        },
        goshopp() {
            this.$router.push({ name: "Index" });
        },
        //导出购物车数据
        exportShoppingCartDatas() {
            this.goodsIdList=[]
            this.goodsIdList=this.multipleSelection.map(item=>{
                return item.id
            })
            if(this.goodsIdList.length == 0){
                this.$message({
                    type: "error",
                    message: '请勾选商品',
                });
                return
            }
            this.exportLoading = true
            exportShoppingCartDatas({
                // goodsName: this.goodsName,
                shoppingCartIds: this.goodsIdList
            }).then(() => {
                this.exportLoading = false
            })
        },
        //获取购物车
        queryListAndPage(type) {
            console.log(this.goodsName, type)
            queryListAndPage({
                // goodsName: this.goodsName,
            }).then((res) => {
                if (res.code == "00000") {
                    
                    this.shopCartList = res.data;
                    this.shopCartList.forEach(item=>{
                        if(item.sourceType==1){
                            item.homePicture="http://img13.360buyimg.com/n1/"+item.homePicture
                        }
                    })
                    res.data.forEach(item=>{
                        if(item.sourceType==1){
                            let obj={}
                            obj.sourceId=item.sourceId
                            obj.nums=item.nums
                            this.stockList.push(obj)
                        }
                        if(item.sourceType==2){
                            let obj={}
                            obj.sourceId=item.sourceId
                            obj.nums=item.nums
                            this.ZKstockList.push(obj)
                        }
                    })
                    // console.log(this.stockList,this.addressName)
                    let data={
                        goodsAddress:this.addressName,
                        map:this.stockList 
                    }
                    queryAndAddress(data).then((res)=>{
                        if(res&&res.code=='00000'){
                            let dataList=JSON.parse(res.data)
                            dataList.forEach(item=>{
                                this.shopCartList.forEach(itm=>{
                                    if(item.sourceId==itm.sourceId){
                                        itm.jdStock=item.jdStock
                                    }
                                })
                            })
                        }
                        
                    })
                    this.SourceIdsAndAddress()
                    
                }
            });
        },
        //修改商品数量
        changeNumber(item){
            
            if (this.filterTime !== null) {
                clearTimeout(this.filterTime)
                this.filterTime = null;
            }
            this.filterTime = setTimeout(()=>{
                console.log(item.nums);
                shoppingCartNumsChange({
                    address: this.addressName,
                    nums: item.nums,
                    shoppingCartId: item.id,
                    skuId: item.productSkuStockId,
                }).then((res) => {
                    // console.log(res);
                    if (res.code != "00000") {
                        this.$message({
                            type: "error",
                            message: res.desc,
                        });
                        this.queryListAndPage();
                    }
                })
            },500)
            
        },
        //全选/选中商品
        handleSelectionChange(val) {
            // console.log(val)
            this.multipleSelection = val;
            this.isIndeterminate = val.length > 0 ? true : false;
            if (val.length == this.shopCartList.length) {
                this.checkAll = true;
                this.isIndeterminate = false;
            } else {
                this.checkAll = false;
            }
        },
        //底部的那个全选按钮
        handleSelectionChange1() {
            //所有商品已选中就取消选择，否则就全选
            if (this.multipleSelection.length == this.shopCartList.length) {
                this.$refs.multipleTable.clearSelection();
            } else {
                this.$refs.multipleTable.toggleAllSelection();
            }
        },
        //删除商品
        deleteGood(id) {
            //如果是数组，就代表是要删除多个商品，不然就是删除单个商品
            let ids = [];
            if (Array.isArray(id)) {
                //通过传进来要删除的id，过滤调包含这些id的商品，剩下的就是删除后的商品数组
                ids = id;
            } else {
                ids = [Number(id)];
            }
            deleteShoppingCart(ids).then((res) => {
                // console.log(res);
                if (res.code == "00000") {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    this.queryListAndPage();
                } else {
                    this.$message({
                        type: "error",
                        message: res.desc,
                    });
                }
            });
        },
        //是否删除商品
        isDeleteGoods(id, index) {
            this.$confirm("是否删除选择的商品？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.deleteGood(id, index);
                })
                .catch(() => {});
        },
        //删除所选商品
        delSelectGoods() {
            if (this.multipleSelection.length > 0) {
                //获取当前选中商品的id数组
                let arr = this.multipleSelection.map((item) => Number(item.id));
                // console.log(arr, "arr");
                this.isDeleteGoods(arr);
            } else {
                this.$message({
                    type: "error",
                    message: "没有选中商品!",
                });
            }
        },
        throttle(fn, delay) {
            var now = Date.now();
            // console.log(now - this.prev,'now - this.prev');
            if (now - this.prev > delay) {
                this.settlement();
                this.prev = Date.now();
            }
        },
        //结算
        settlement() {
            if (!authorityJudgment(3)) {
                this.$message({
                    type: "error",
                    message: "当前账号没有购买权限!",
                });
                return;
            }
            if (this.multipleSelection.length == 0) {
                this.$message({
                    type: "error",
                    message: "没有选中商品!",
                });
                return;
            }
            if (this.multipleSelection.length >100) {
                this.$message({
                    type: "error",
                    message: "购买商品种类一次性不能超过100条!",
                });
                return;
            }
            // let list=''
            // if(this.multipleSelection.length!=0){
            //     this.multipleSelection.filter(item=>{
            //         console.log(item.kind==3&&item.kind==2)
            //         if(item.kind==3&&item.kind==2){
            //             list=2
            //             this.$message({
            //                 type: "error",
            //                 message: "禹佳卡不能和其他商品一起下单!",
            //             });
            //         }else if(item.kind==3){
            //             list=''
            //         }
            //     })
            // }
            // if(list==''){
            this.GoodsInfor=''
            sessionStorage.setItem('goodsInfor','')
            this.MultipleSelection=JSON.stringify(this.multipleSelection)
            sessionStorage.setItem('multipleSelection',JSON.stringify(this.multipleSelection))
            this.$router.push({
                name: "GoodsClose",
            });
            
        },
        //猜你喜欢
        getList(pageIndex,pageSize){
            let data={
                brand: [],
                myPage:{
                    pageNum:pageIndex,
                    pageSize:pageSize
                },
                orderBy: 'id'
            }
            Inquire(data).then((data) => {
                if(data&&data.code==='00000'){
                    this.fornum=(data.data.goodsList.length % 5)===0 ?(data.data.goodsList.length / 5) : parseInt(data.data.goodsList.length / 5)
                    let jsonArr=[]
                    data.data.goodsList.forEach((item,index)=>{
                        jsonArr.push(item)
                        if((index +1) % 5 ===0){
                            this.orderList.push(jsonArr)
                            jsonArr=[]
                        }else if(index === data.data.goodsList.length -1){
                            this.orderList.push(jsonArr)
                        }
                    })
                    this.shoppData=this.orderList[0]
                }
            });
        },
        carouselChange (index1) {
            this.shoppData = this.orderList[index1]
        },
        //商品详情
        gotocart(id){
            if(this.$routerWin){
                const { href } = this.$router.resolve({ name: "goodsDetail", query: { id: id } });
                window.open(href,'_blank');
            } else {
                this.$router.push({ name: "goodsDetail", query: { id: id } });
            }
        },
        //复选框禁用
        selectable(row){
            // console.log(index)
            if(row.sourceType===0){
                if(row.state==1&&row.stock==0){
                    return false
                }else if(row.state==6&&row.stock==0){
                    return false
                }else if(row.state==2&&row.stock==0){
                    return false
                }else if(row.state==3){
                    return false
                }else{
                    return true
                }
            }else if(row.sourceType===1){
                if(row.jdStock==300){
                    return false
                }else{
                     return true
                }
            }else if(row.sourceType===2){
                if(row.jdStock=='无货'){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }
        },
        //获取地址
        getAddress() {
            let data = {
                addressInfo: "",
                id: "",
                labelName: "",
            };
            getAddress(data).then((data) => {
                if (
                    data.data &&
                    data.code === "00000" &&
                    data.data.id &&
                    data.data.isDefault
                    ) {
                    this.name = data.data.receiveName;
                    this.defaultAddress = data.data;
                    if (data.data.address != null) {
                        this.addressName =
                        data.data.province +
                        data.data.city +
                        data.data.area +
                        data.data.address;
                    } else {
                        this.addressName =
                        data.data.province + data.data.city + data.data.area;
                    }
                    this.addressList.push(this.addressName);
                    this.addressId = data.data.id;
                    this.isDefault = data.data.isDefault;
                }
            });
        },
        //地址选择
        chooseAddress(e) {
            let arr = pcas;
            let address = "";
            e.forEach((item) => {
                let tempArr = arr.filter((items) => items.value == item);
                if (tempArr.length != 0) {
                address += tempArr[0].label;
                }
                arr = tempArr.length != 0 ? tempArr[0].children : [];
            });
            // console.log(address, "address");
            this.addressName = address;
            this.$refs.popover.handleBlur();
            this.queryListAndPage();
        },
        getLocation() {
            this.$jsonp("https://apis.map.qq.com/ws/location/v1/ip", {
                key: "UAJBZ-M7B3F-3OSJ7-JKXF2-UHLCK-XTBBK", //腾讯key（我的测试key）
                output: "jsonp",
            }).then((res) => {
                // console.log(res, "resres");
                if (res.status === 0) {
                let { city, district, province } = res.result.ad_info;
                this.city = city;
                this.district = district;
                this.province = province;
                this.localArress = res.result.ad_info;
                this.addressName = province + city + district;
                this.addressList.push(this.addressName);
                }
            });
        },
        restaddress() {
            let data = {
                addressInfo: "",
                id: "",
                labelName: "",
            };
            restaddress(data).then((data) => {
                // console.log(data, "data");
                if (data && data.code === "00000") {
                    this.otherAddressList = data.data;
                    let arr = data.data.map((item) => {
                        return item.province + item.city + item.area;
                    });
                    // console.log(arr, "arr");
                    this.addressList = [...this.addressList, ...arr];
                    this.addressList = [...new Set(this.addressList)];
                }
            });
        },
        //导出批量下单模板 
        exportBulkorderDatas(index){
            this.goodsIdList=[]
            this.goodsIdList=this.multipleSelection.map(item=>{
                return item.id
            })
            console.log(this.goodsIdList)
            if(this.goodsIdList.length == 0){
                this.$message({
                    type: "error",
                    message: '请勾选商品',
                });
                return
            }
            if (index == 1) {
                this.exportLoading1 = true
                exportBulkorderDatas({
                    // goodsName: this.goodsName,
                    shoppingCartIds: this.goodsIdList
                }).then(() => {
                    this.exportLoading1 = false
                });
            } else {
                this.exportLoading2 = true
                exportShoppingCartBatchDataAll({
                    shoppingCartIds: this.goodsIdList
                }).then(() => {
                    this.exportLoading2 = false
                });
            }
            
        },
        addresshandleBlur(item){
            this.addressName = item;
            this.$refs.popover.handleBlur();
            this.queryListAndPage();
        },
        SourceIdsAndAddress(){
            let data={
                goodsAddress:this.addressName,
                map:this.ZKstockList 
            }
            querySourceIdsAndAddress(data).then((res)=>{
                if(res&&res.code=='00000'){
                    let dataList=res.data.data
                    dataList.forEach(item=>{
                        this.shopCartList.forEach(itm=>{
                            if(item.sourceId==itm.sourceId){
                                itm.jdStock=item.stock
                            }
                        })
                    })
                }
                
            })
        }
    }
};
</script>

<style lang="scss">
.shoppCart-box {
    background: #f7f7f7;

    .shopp_cart {
        margin: 50px 0 10px 0;
        background: #fff;
        padding: 15px;
        .shopp_cart_top {
            margin-bottom: 13px;
            .address {
                padding: 3px 8px;
                border: 1px solid #ddd;
            }
        }
        .shoppCart {
            .tableTail {
                height: 70px;
                position: relative;
                z-index: 100;
                box-shadow: -2px -2px 12px 0 rgba(0, 0, 0, 0.1);
                margin-top: 10px;
                .left {
                    padding: 15px 12px;
                }
                .settlement {
                    height: 70px;
                    height: 100%;
                    background-color: #f2270c;
                    color: #fff;
                    padding: 0 20px;
                }
                .settlement:hover {
                    cursor: pointer;
                }
                .b2b2b2 {
                    color: #b2b2b2 !important;
                }
            }
            .goodsImg {
                width: 80px;
                height: 80px;
                margin-right: 5px;
            }
            .goodsNo{
                position: absolute;
                bottom: 0px;
                width: 100%;
                background: #999;
                text-align: center;
                color: #fff;
            }
             .title_name{
                color: #fff;
                background: #CD9F49;
                padding: 2px 4px;
                font-size: 12px;
                border-radius: 4px;
            }
            .goodsName {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
        .no-shoppCart {
            height: 473px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 206px;
                height: 135px;
                margin-bottom: 37px;
            }
            span {
                font-size: 16px;
                color: #999;
            }
            .goshopp {
                color: #333;
            }
        }
        .tableInpNumBox {
            .cell {
                overflow: unset;
            }
        }
        .tableInpNum {
            position: relative;
            .tipsText {
                color:red;
                position: absolute;
                top: 90%;
                width: 100%;
                line-height: 16px;
            }
        }
    }
    .guesslike {
        background: #fff;
        margin-bottom: 40px;
        padding: 25px 17px 17px;
        .guesslike_top {
            height: 33px;
            line-height: 33px;
        }
        .guesslike_box {
            .el-carousel {
                height: 326px;
            }
            .el-carousel__arrow {
                background: #fff;
                color: #333;
                font-size: 18px;
            }
            .el-carousel__arrow--left {
                left: 0;
            }
            .el-carousel__arrow--right {
                right: 0;
            }
            .el-carousel__button {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #797979;
            }
            .guesslike_swiper {
                display: flex;
                .swiper-slide {
                    width: 20%;
                }
                .product-item {
                    padding: 26px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    img {
                        width: 150px;
                        height: 150px;
                        margin-bottom: 18px;
                    }
                    .name {
                        height: 38px;
                        font-size: 14px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .title_name{
                        color: #fff;
                        background: #CD9F49;
                        padding: 0 3px;
                    }
                    .item_price{
                        width: 100%;
                        text-align: left;
                        font-weight: bold;
                        color: red;
                        font-size: 18px;
                        margin-top: 10px;
                    }
                    .item-btn {
                        margin-top: 15px;
                        background-color: #fff;
                        border: 1px solid #d2d2d2;
                        color: #434343;
                        text-align: center;
                        padding-left: 16px;
                        padding-right: 16px;
                        height: 32px;
                        line-height: 29px;
                        border-radius: 2px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>